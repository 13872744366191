<template>

  <div class="editor">
    <div class="clearfix editor__content">
      <div class="my-3 text-center">
        <h1>{{ sheet.name }}</h1>
      </div>
      <p v-if="sheet.data && sheet.data.definition" class="mb-3 text-center" v-html="sheet.data.definition" />
      <blockquote v-if="sheet.data && sheet.data.pilote_success_keys" class="success mb-3">
        <div class="px-3 py-2">
          <h2 class="text-success"><i class="icon-stack-check mr-1" /> {{ $t('modules.quest.sheet.success_keys') }}</h2>
          <div v-html="sheet.data.pilote_success_keys"></div>
        </div>
      </blockquote>
      <blockquote v-if="sheet.data && sheet.data.pilote_goals" class="info mb-3">
        <div class="px-3 py-2">
          <h2><i class="icon-target mr-1" /> {{ $t('modules.quest.sheet.goals') }}</h2>
          <div v-html="sheet.data.pilote_goals"></div>
        </div>
      </blockquote>
      <div v-if="sheet.data && sheet.data.pilote_embed_video" class="pilote-embed-video mb-3">
        <div v-html="sheet.data.pilote_embed_video"></div>
      </div>
      <div v-html="sheet.pilote_description"></div>
    </div>

    <div
      v-if="
        sheet.data.actions_related &&
        sheet.data.actions_related.length > 0 &&
        currentWorld.modules.actionsboard
      "
      class="bg-light rounded px-3 pt-3 pb-2 mb-3"
    >
      <el-card-title class="font-weight-semibold" :title="$t('modules.wiki.content.actions_related')" />
      <div class="mt-2">
        <div class="" v-for="action in sheet.data.actions_related">
          <action-create-box :name="action" />
        </div>
      </div>
    </div>
    <div
      v-if="
        templatesRelated != null &&
        templatesRelated.length > 0 &&
        currentWorld.modules.templates
      "
      class="bg-light rounded px-3 pt-3 pb-1 mb-3"
    >
      <el-card-title class="font-weight-semibold" :title="$t('modules.wiki.content.templates_related')" />
      <div class="row mt-2">
        <div
           v-for="template in templatesRelated"
           :key="`template-${template.tpl_entity.id}`"
           class="col-lg-6 col-md-12 pb-3"
         >
          <templates-template-card
            :template="template.tpl_entity"
          />
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import ActionCreateBox from '@/../Modules/Actionsboard/components/ActionCreateBox'
import TemplateCardComponent from '@/../Modules/Templates/components/TemplateCard'
import TemplatesHelper from '@/../Modules/Templates/helpers/TemplatesHelper'

import {
  TEMPLATES_GET_LIST,
} from '@/../Modules/Templates/mutations-types'

import _ from 'lodash'

export default {
  name: 'SheetPilote',
  extends: TemplatesHelper,
  components: {
    'action-create-box': ActionCreateBox,
    'templates-template-card': TemplateCardComponent,
  },
  props: {
    sheet: {
      type: Object,
      required: true,
    }
  },
  data () {
    return {
      templates: [],
    }
  },
  mounted () {
    this.getTemplatesList()
  },
  methods: {
    getTemplatesList () {
      if (!this.currentWorld.modules.templates) {
        return // If template module not activated, skip
      }
      this.$store.dispatch('templates/' + TEMPLATES_GET_LIST, this.$route.params.world).then(templates => {
        this.templates = templates
      })
    },
  },
  computed: {
    currentWorld () {
      return this.$store.getters['auth/getWorld']
    },
    templatesFormatted () {
      return this.formatTemplates(this.templates)
    },
    templatesRelated () {
      if (!this.sheet.data.templates_related || this.sheet.data.templates_related.length == 0) {
        return null
      }
      let mapping = _.map(this.sheet.data.templates_related, url => {
        let urlDecoded = decodeURI(url)
        let moduleName = new URL(urlDecoded).searchParams.get('filter[entity_label][0]')
        let templateName = new URL(urlDecoded).searchParams.get('search')
        let tplEntity = _.find(this.templatesFormatted, {
          'entity_label': moduleName,
          'name': templateName,
        })
        if (!tplEntity) {
          return false
        }
        return {
          url: urlDecoded,
          module: moduleName,
          name: templateName,
          tpl_entity: tplEntity,
        }
      })
      return _.filter(mapping, elem => elem != false)
    },
  },
}
</script>

<style lang="scss" scoped>
.editor__content {
  max-width: 800px;
  margin: 0 auto;
}
::v-deep .pilote-embed-video {
  video {
    min-height: 400px;
  }
}
</style>
